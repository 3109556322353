<template>
  <div id="business">
    <div class="pc">
      <img src="../../assets/business/banner.png" alt="" class="banner" />

      <div class="service">
        <h1>核心业务</h1>
        <div><img src="../../assets/home/line.png" alt="" /></div>
        <p>一流的队伍 创造一流的品质  展现一流的风貌</p>
        <ul>
          <li>
            <div class="team">
              <img src="../../assets/business/sky1.png" alt="" />
            </div>
            <div class="team_info">
              <h3>工厂环境</h3>
              <p>干净整洁的工作环境</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img src="../../assets/business/sky2.png" alt="" />
            </div>
            <div class="team_info">
              <h3>招聘现场</h3>
              <p>输送大量省外人力</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img src="../../assets/business/sky3.png" alt="" />
            </div>
            <div class="team_info">
              <h3>合作企业</h3>
              <p>与多家知名企业合作</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img src="../../assets/business/sky4.jpeg" alt="" />
            </div>
            <div class="team_info">
              <h3>专业团队</h3>
              <p>近百名专业的人力资源老师 为你服务</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img src="../../assets/business/sky5.jpeg" alt="" />
            </div>
            <div class="team_info">
              <h3>学习交流</h3>
              <p>企业员工定期进行培训学习</p>
            </div>
          </li>
          <!-- <li>
            <div class="team">
               <img src="https://img.js.design/assets/smartFill/img346164da74c4b8.jpg" alt="">
            </div>
            <div class="team_info">
              <h3>专业团队</h3>
              <p>近千名专业的人力资源老师 为你服务</p>
            </div>
          </li>
          <li>
            <div class="team">
               <img src="https://img.js.design/assets/smartFill/img345164da74c4b8.jpeg" alt="">
            </div>
            <div class="team_info">
              <h3>专业团队</h3>
              <p>近千名专业的人力资源老师 为你服务</p>
            </div>
          </li> -->
          <li>
            <div class="team">
              <img src="../../assets/business/sky6.jpeg" alt="" />
            </div>
            <div class="team_info">
              <h3>公司团建</h3>
              <p>共同建设创造企业文化</p>
            </div>
          </li>
        </ul>
      </div>
      <img src="../../assets/business/bgc1.png" alt="" class="bgc1" />
    </div>
    <div class="mobile">
      <img src="../../assets/business/banner.png" alt="" class="banner" />
      <div class="service" id="services">
        <h1>核心业务</h1>
        <p>劳务派遣、人力外包等多方面服务</p>
        <div class="line">
          <img src="../../assets/home/m_line.png" alt="" />
        </div>
        <p>
          成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、岗位外包服务、业务外包服务、岗位外包服务、业务外包服务、
        </p>
        <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
        <div class="content">
          <div class="box">
            <h1>劳务派遣</h1>
            <div><img src="../../assets/business/star.png" alt="" /></div>
            <p>
              公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业奉行“服务创新，融入企业奉行“服务创新，融入企业....
            </p>
            <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
          </div>
          <div class="box">
            <h1>劳务派遣</h1>
            <div><img src="../../assets/business/star.png" alt="" /></div>
            <p>
              公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业奉行“服务创新，融入企业奉行“服务创新，融入企业....
            </p>
            <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
          </div>
          <div class="box">
            <h1>劳务派遣</h1>
            <div><img src="../../assets/business/star.png" alt="" /></div>
            <p>
              公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业奉行“服务创新，融入企业奉行“服务创新，融入企业....
            </p>
            <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
          </div>
          <div class="box">
            <h1>劳务派遣</h1>
            <div><img src="../../assets/business/star.png" alt="" /></div>
            <p>
              公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业奉行“服务创新，融入企业奉行“服务创新，融入企业....
            </p>
            <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
          </div>
          <div class="more" @click="more">
            更多服务<i class="el-icon-arrow-down"></i>
          </div>
          <div class="box">
            <h1>劳务派遣</h1>
            <div><img src="../../assets/business/star.png" alt="" /></div>
            <p>
              公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业奉行“服务创新，融入企业奉行“服务创新，融入企业....
            </p>
            <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
          </div>
          <div class="box">
            <h1>劳务派遣</h1>
            <div><img src="../../assets/business/star.png" alt="" /></div>
            <p>
              公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业奉行“服务创新，融入企业奉行“服务创新，融入企业....
            </p>
            <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
          </div>
          <div class="box">
            <h1>劳务派遣</h1>
            <div><img src="../../assets/business/star.png" alt="" /></div>
            <p>
              公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业奉行“服务创新，融入企业奉行“服务创新，融入企业....
            </p>
            <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
          </div>
          <div class="box">
            <h1>劳务派遣</h1>
            <div><img src="../../assets/business/star.png" alt="" /></div>
            <p>
              公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业奉行“服务创新，融入企业奉行“服务创新，融入企业....
            </p>
            <img src="../../assets/business/m_bgc1.png" alt="" class="banner" />
          </div>
        </div>
      </div>
      <img src="../../assets/business/m_bgc2.png" alt="" class="banner" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      property: "value",
    };
  },

  methods: {
    more() {
      let service = document.querySelector("#services"); //权重问题用class 不行
      let more = document.querySelector(".more");
      service.style.height = "auto"; //取消文字容器高度限制
      service.style.overflow = "unset";
      more.style.display = "none";
    },
  },
};
</script>

<style lang="less" scoped>
#business {
  .pc {
    .banner {
      width: 100%;
    }
    .service {
      width: 100%;
      height: 81.5rem;
      // background-image: url(../../assets/home/bgc1.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: -1;
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: rgba(13, 13, 13, 1);
      }
      p {
        font-size: 14px;
        color: rgba(112, 112, 112, 1);
      }
      ul {
        width: 75%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
        justify-content: space-around;
        margin-top: 8.125rem;
        li {
          width: 26rem;
          height: 26.875rem;
          border: 1px solid rgba(166, 166, 166, 1);
          background-color: white;
          list-style: none;
          margin-bottom: 20px;
          .team {
            width: 25rem;
            height: 13.75rem;
            background-color: skyblue;
            margin: auto;
            margin-top: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .team_info {
            width: 12rem;
            margin: auto;
            h3 {
              font-size: 22px;
              padding: 2.375rem 0 1.875rem 0;
            }
            p {
              font-size: 1rem;
              color: rgba(56, 56, 56, 1);
            }
          }
        }
      }
    }
    .bgc1 {
      width: 100%;
      display: flex;

      // flex-direction: row;
      // justify-content: flex-end;
    }
  }
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  #business {
    .pc {
      display: none;
    }
    .mobile {
      display: block;
      .banner {
        width: 100%;
        display: flex;
      }
      .service {
        width: 90%;
        margin: auto;
        height: 1580px;
        overflow: hidden; /*设置文案容器高度，超出部分隐藏*/
        // overflow:unset;
        margin-top: 20px;
        h1 {
          font-size: 14px;
          font-weight: bold;
          color: rgba(56, 56, 56, 1);
          text-align: left;
        }
        .line {
          text-align: left;
          img {
            width: 53px;
          }
        }
        p {
          font-size: 10px;
          text-align: left;
          font-size: 12px;
        }
        p:nth-of-type(2) {
          text-indent: 24px;
          color: rgba(128, 128, 128, 1);
          margin-bottom: 15px;
        }

        .content {
          .box {
            margin-bottom: 10px;
            h1 {
              text-align: center;
              font-size: 14px;
              font-weight: normal;
            }
            p {
              margin-bottom: 15px;
            }
          }
          .more {
            font-size: 12px;
            color: rgba(166, 166, 166, 1);
            padding: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
